<script>
  import countryUtil from "../../utils/countryUtil";
  export default {
    data(){
      return{
        fullData:[],
        countryList:[],
        sel:{},
      }
    },
    props:{
      value:{
        type:Object,
        default: {}
      },
      show:{
        type:Boolean,
        default: false
      }
    },
    methods:{
      confirmCountry(item){
        this.$emit('input',item)
        this.close()
      },
      initCountry(){
        let cnData = countryUtil.getCnData();
        for(let item of cnData){
          try {
            let icon = require('@/assets/country_list/' + item.code + '@3x.png');
            item.icon = icon
            this.countryList.push(item)
          } catch (e) {

          }
        }
        this.fullData=Object.assign([],this.countryList)
      },
      close(){
        this.$emit('update:show',false);
      },
      changeFilter(data){
        const inputValue=data.target.value;
        if(!inputValue){
          this.countryList=this.fullData;
          return
        }
        this.countryList=this.fullData.filter(item=>{
          return item.phoneCode.indexOf(inputValue.replace("+","")) > -1||item.name.indexOf(inputValue) > -1;
        })
      }
    },
    created() {
      this.initCountry();
    }
  }
</script>

<template>
  <div class="root" v-if="show">
    <div class="content">
      <div class="controller">
        <div class="button" @click="close()">取消</div>
        <div class="title">選擇國家</div>
        <div class="button confirm"></div>
      </div>
      <input @input="changeFilter" class="query" placeholder="輸入區號、國家/地區名稱進行過濾"></input>
      <div class="data">
        <div v-for="(item,index) in countryList" @click="confirmCountry(item)" :key="index" class="item">
          <img :src="item.icon" class="item_icon">
          <div class="item_code">+{{item.phoneCode}}</div>
          <div class="item_name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.root{
  position: absolute;
  background: rgba(0,0,0,.6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
}
.content{
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.controller{
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 13px;
}
.button{
  padding: 10px;
}
.confirm{
  color: #25d165;
}
.title{
  flex: 1;
  text-align: center;
  font-size: 16px;
}
.data{
  max-height: 40vh;
  overflow: auto;
  min-height: 40vh;
}
.item{
  display: flex;
  padding: 10px;
}
.item_icon{
  width: 20px;
}
.item_name{
  margin-left: 20px;
  font-size: 14px;
}
.query{
  margin: 10px;
  border: 1px solid #25d165;
  border-radius: 5px;
  padding: 10px 10px;
  font-size: 14px;
  outline: none;
}
.item_code{
  width: 50px;
  margin-left: 20px;
  color: white;
  background: #25d165;
  text-align: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  box-sizing: border-box;
  border-radius: 2px;
}
</style>